import React from 'react';
import img from '../../assets/Img/int1.jpg'
import img2 from '../../assets/Img/int2.jpg'
import { FaThreads,FaTiktok  } from 'react-icons/fa6';

const Interesting = () => {
    return (
        <section className="section__container team" id="اهتمامنا">
            <div className="service__header__content flex-btn-contact-between">
                <h2 className="section__header">اهتمامنا</h2>
                <div className="flex-btn-contact">
                <a className="btn-sec" href="https://wa.me/+20109252712" target="_blank" data-aos="fade-up" data-aos-delay="300">
              تواصل معنا
            </a>
                </div>
            </div>

            <div className="container">
                <div className="box">
                    <div className="data">
                        <img src={img} alt="" />
                        <div className="social">
                            <a href="https://www.facebook.com/Gharam.ml" target="_blank" rel="noopener noreferrer">
                                <svg
                                    stroke="#3b5998"
                                    fill="#3b5998"
                                    strokeWidth="0"
                                    viewBox="0 0 320 512"
                                    height="1.4em"
                                    width="1.4em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/gharamsoltan/" target="_blank" rel="noopener noreferrer">
                                <svg
                                    stroke="#C13584"
                                    fill="#C13584"
                                    strokeWidth="0"
                                    viewBox="0 0 448 512"
                                    height="1.4em"
                                    width="1.4em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                </svg>
                            </a>
                            <a href="https://www.threads.net/@gharamsoltan" target="_blank" rel="noopener noreferrer">
    <FaThreads color="#000000" size="24" /> 
</a>
<a href="https://www.tiktok.com/@gharamsoltan" target="_blank" rel="noopener noreferrer">
    <FaTiktok color="#000000" size="24" />
</a>
                        </div>
                    </div>
                    <div className="info">
                        <h3>قسم التصوير الفوتوغرافي</h3>
                        <p>مرحبًا بكم في قسم التصوير الفوتوغرافي لدينا، حيث نلتقط اللحظات الثمينة ونحولها إلى ذكريات خالدة. سواء كنت تبحث عن جلسة تصوير داخل الاستوديو أو في الهواء الطلق، فإن فريقنا من المصورين المحترفين مستعد لتلبية جميع احتياجاتك.</p>
                    </div>
                </div>
                <div className="box">
                    <div className="data">
                        <img src={img2} alt="" />
                        <div className="social">
                            <a href="https://www.facebook.com/Gharam.ml" target="_blank" rel="noopener noreferrer">
                                <svg
                                    stroke="#3b5998"
                                    fill="#3b5998"
                                    strokeWidth="0"
                                    viewBox="0 0 320 512"
                                    height="1.4em"
                                    width="1.4em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/gharamsoltan/" target="_blank" rel="noopener noreferrer">
                                <svg
                                    stroke="#C13584"
                                    fill="#C13584"
                                    strokeWidth="0"
                                    viewBox="0 0 448 512"
                                    height="1.4em"
                                    width="1.4em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                </svg>
                            </a>
                            <a href="https://www.threads.net/@gharamsoltan" target="_blank" rel="noopener noreferrer">
    <FaThreads color="#000000" size="24" /> 
</a>
<a href="https://www.tiktok.com/@gharamsoltan" target="_blank" rel="noopener noreferrer">
    <FaTiktok color="#000000" size="24" />
</a>
                        </div>
                    </div>
                    <div className="info">
                        <h3>مرحبًا بكم في قسم الميك أب والتجميل</h3>
<p>مرحبًا بكم في قسم الميك أب والتجميل 
تألقي بجمال ملفت مع خبيرة التجميل غرام سلطان
 ، حيث نُبرز جمالك الطبيعي ونضيف لمسات من الأناقة والرقي.
</p>                    </div>
                </div>


            </div>
        </section>
    );
};

export default Interesting;
