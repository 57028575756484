import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Img/logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectLogin, userLogin } from "../../app/Feature/API/LoginSlice";
import CookieService from "../../Services/CookiesServices";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, data } = useSelector(selectLogin);

  const jwt = CookieService.get("jwt");
  if (jwt) {
    navigate("/moderator", { replace: true });
  }

  const handleNavigate = useCallback(() => {
    navigate("/moderator", { replace: true });
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    if (!email) {
      setEmailError("يرجى ملء البريد الالكتروني");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("يرجى ملء كلمة المرور");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!valid) {
      toast.error("يرجى ملء جميع الحقول");
      return;
    }

    const loginData = { email, password };

    dispatch(userLogin(loginData));
  };

  return (
    <section className="bg-white h-[100vh]">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
          <img className="w-52" src={logo} alt="غرام سلطان" />
        </div>
        <div className="w-full bg-gray-50 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
              تسجيل الدخول
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  البريد الالكتروني
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className={`bg-white border ${
                    emailError ? "border-red-500" : "border-gray-300"
                  } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  placeholder="البريد الالكتروني"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">{emailError}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  كلمه المرور
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className={`bg-white border ${
                    passwordError ? "border-red-500" : "border-gray-300"
                  } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                )}
              </div>
              <button
                type="submit"
                className="w-full text-white bg-[#E9C357] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                disabled={loading}
              >
                {loading ? "جاري تسجيل الدخول..." : "تسجيل الدخول"}
              </button>
              {error && (
                <p className="text-red-500 text-sm mt-1">
                  فشل تسجيل الدخول. يرجى المحاولة مرة أخرى.
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
